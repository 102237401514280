import React from "react"
import PositionLettersList from "../../components/position-letters-list"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

export default function PositionLetters() {
  const oppositionFiles = [
    {
      name: "2021-0321 SB10 oppose letter.pdf",
      label: "SB 10",
    },
    {
      name: "2021-0321 SB290 oppose letter.pdf",
      label: "SB 290",
    },
    {
      name: "2021-0321 SB477 oppose letter.pdf",
      label: "SB 477",
    },
    {
      name: "2021-0403 SB478 oppose letter.pdf",
      label: "SB 478",
    },
    {
      name: "2021-0408 AB68 oppose letter.pdf",
      label: "AB 68",
    },
    {
      name: "2021-0408 AB215 oppose letter.pdf",
      label: "AB 215",
    },

    {
      name: "2021-0408 AB1401 oppose letter.pdf",
      label: "AB 1401",
    },
    {
      name: "2021-0408 SB9 oppose letter.pdf",
      label: "SB 9",
    },
    {
      name: "2021-0416 AB115 oppose letter.pdf",
      label: "AB 115",
    },
    {
      name: "2021-0416 AB678 oppose letter.pdf",
      label: "AB 678",
    },
    {
      name: "2021-0416 AB989 oppose letter.pdf",
      label: "AB 989",
    },
    {
      name: "2021-0416 SB6 oppose letter.pdf",
      label: "SB 6",
    },
    {
      name: "2021-0416 SB8 oppose letter.pdf",
      label: "SB 8",
    },
    {
      name: "CCLC Opposes SB 55.pdf",
      label: "SB 55",
    },
    {
      name: "CCLC Opposes AB 1398.pdf",
      label: "AB 1398",
    },
    {
      name: "2021-0422 AB1322 oppose letter.pdf",
      label: "AB 1322",
    },
  ]

  const supportFiles = [
    {
      name: "2021-0312 SB15 support letter.pdf",
      label: "SB 15",
    },
    {
      name: "2021-0408 SB765 support letter.pdf",
      label: "SB 765",
    },
    {
      name: "2021-0408 AB787 support letter.pdf",
      label: "AB 787",
    },
    {
      name: "2021-0416 AB617 support letter.pdf",
      label: "AB 617",
    },
    {
      name: "2021-0416 AB1258 support letter.pdf",
      label: "AB 1258",
    },
    {
      name: "2021-0430 ACA7 support letter.pdf",
      label: "ACA 7",
    },
  ]

  return (
    <Layout>
      <SEO title="2021 Position Letters" />
      <h1>2021 Position Letters</h1>
      <p>
        These are the letters that CCLC submitted in OPPOSITION to state bills
        which we believe take away local control of matters related to land-use
        and zoning:
      </p>
      <PositionLettersList files={oppositionFiles} />
      <p>
        These are the letters that CCLC submitted in SUPPORT of state bills
        which we believe protect cities local control of matters related to
        land-use and zoning:
      </p>
      <PositionLettersList files={supportFiles} />
    </Layout>
  )
}
